import React from 'react'
import "../App.css"
import ProductCarousel from './ProductCarousel'
import productsData from '../productsData'
import Team from './Team'

export default function Home() {
  return (
    <div>
        <img src='images/home-img.jpeg' className='home-img' alt='home-banner'/>
        <div className='marquee'>
            <p className='marquee-content'>CRAFTS 'N' CREATIONS </p>
            <p className='marquee-content'>CRAFTS 'N' CREATIONS </p>
            <p className='marquee-content'>CRAFTS 'N' CREATIONS </p>
            <p className='marquee-content'>CRAFTS 'N' CREATIONS </p>
            <p className='marquee-content'>CRAFTS 'N' CREATIONS </p>
            <p className='marquee-content'>CRAFTS 'N' CREATIONS </p>
            <p className='marquee-content'>CRAFTS 'N' CREATIONS </p>
            <p className='marquee-content'>CRAFTS 'N' CREATIONS </p>
            <p className='marquee-content'>CRAFTS 'N' CREATIONS </p>
            <p className='marquee-content'>CRAFTS 'N' CREATIONS </p>
            
        </div>
        <ProductCarousel products={productsData} />
        <br />
        <Team />
    </div>
  )
}

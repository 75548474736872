import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import './App.css';
import Footer from './pages/Footer';
import Icons from './pages/Icons';
import CurrencyDropdown from './pages/CurrencyDropdown';
import Navbar from './pages/Navbar';

const Layout = () => {
    const [selectedCurrency, setSelectedCurrency] = useState('INR (₹)');

    const currencies = ['USD ($)', 'EUR (€)', 'GBP (£)', 'JPY (¥)', 'CAD (CA$)', 'AUD (A$)', 'CNY (CN¥)', 'INR (₹)']; 

    const handleCurrencyChange = (currency) => {
        setSelectedCurrency(currency);
    };

    return (
        <div className='full'>
            <div className='announcement-bar'>
                <div className='bar-icons top'><Icons /></div>
                <div className='announcement-text'>FREE SHIPPING ON ORDERS ABOVE ₹500</div>
                <CurrencyDropdown currencies={currencies} selectedCurrency={selectedCurrency} onSelectCurrency={handleCurrencyChange} />
            </div>
            <div className='logo-area'>
                <img className='logo' src='images/cnc-logo.jpeg' alt='logo' />
            </div>
            <Navbar />
            <div className="body">
                <Outlet />
            </div>
            <Footer />
        </div>
    );
};

export default Layout;

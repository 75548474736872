import React, { useState } from 'react'

export default function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  return (
    <nav className="navbar">
      <ul className={`navbar-nav ${isOpen ? 'active' : ''}`}>
        <li className="nav-item">
          <a href="#" className="nav-link">HOME</a>
        </li>
        <li className="nav-item">
          <a href="#" className="nav-link">SHOP NEW</a>
        </li>
        <li className="nav-item">
          <a href="#" className="nav-link">SHOP ALL</a>
        </li>
        <li className="nav-item dropdown">
          <a href="#" className="nav-link dropdown-toggle">ACCESSORIES</a>
          <ul className="dropdown-menu">
            <li><a href="#">♡ ALL ACCESSORIES</a></li>
            <li><a href="#">♡ BRACELETS</a></li>
            <li><a href="#">♡ KEYCHAINS</a></li>
            <li><a href="#">♡ EARRINGS</a></li>
            <li><a href="#">♡ NECLACES</a></li>
            <li><a href="#">♡ RINGS</a></li>
          </ul>
        </li>
        <li className="nav-item">
          <a href="#" className="nav-link">HOME DECOR</a>
        </li>
        <li className="nav-item">
          <a href="#" className="nav-link">TRENDING</a>
        </li>
      </ul>
      <div id="menu-toggle" onClick={toggleMenu}>
        <div className={`bar ${isOpen ? 'active' : ''}`}></div>
        <div className={`bar ${isOpen ? 'active' : ''}`}></div>
        <div className={`bar ${isOpen ? 'active' : ''}`}></div>
      </div>
    </nav>
  )
}

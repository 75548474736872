import React from 'react'
import { useLocation } from 'react-router-dom';
import OrderForm from './OrderForm';

export default function SingleProduct() {
    window.scrollTo(0, 0);
    const location = useLocation();
    const product = location.state.product;

    return (
        <div className='single-product-container'>
            <div className="product-details">
                <div className="product-image half">
                    <img src={product.imageUrl} alt={product.name} />
                </div>
                <div className="product-info half">
                    <h2>{product.name}</h2>
                    <p className="price">₹{product.price}</p>
                    <OrderForm product={product} />
                </div>
            </div>

        </div>
    );
}

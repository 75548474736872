import React from 'react'

const ProductCard = ({product}) => {
    return (
        <div className='card'>
            <img src={product.imageUrl} alt='img'/>
            <br />
            <div>{product.name}</div>
            <div>₹{product.price}</div>
        </div>
    )
}

export default ProductCard;
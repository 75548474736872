import React from 'react'
import Icons from './Icons'
import { IoIosCall } from "react-icons/io";
import { MdEmail } from "react-icons/md";

export default function Footer() {
    return (
        <>
            <div className='footer-container'>
                <div className='footer-left'>
                    <h4>Connect with us!</h4>
                    <br />
                    <Icons />
                    <br />
                    <div>
                        <p><IoIosCall /> +91-9899690034</p>
                        <p><MdEmail /> craftsncreations2024@gmail.com</p>
                    </div>
                </div>
                <div className='footer-mid'>
                    <h4>Customer Service</h4>
                    <br />
                    <div className='links'>
                        <ul>
                            <li><a href='/'>About Us</a></li>
                            <li><a href='/'>Return Portals</a></li>
                            <li><a href='/'>Shipping</a></li>
                            <li><a href='/'>Returns & Exchnages Policy</a></li>
                            <li><a href='/'>Privacy Policy</a></li>
                            <li><a href='/'>Terms Of Service</a></li>
                            <li><a href='/'>Contact Us</a></li>
                        </ul>
                    </div>
                </div>
                <div className='footer-right'>
                    <h4>Stay in Touch</h4>
                    <br />
                    <p>Sign up for exclusive offers, events and more.</p>
                    <br />
                    <form className='footer-form' action='https://formspree.io/f/mleqpgny' method='post'>
                        <input className="input" name="email" placeholder="Email" type="email" autoComplete='off' />
                        <button class="button-sub" type='submit'>Subscribe</button>
                    </form>
                    <br />
                    <Icons />
                </div>
            </div>
            <div className='footer-bottom'>
                <div>CEO AISHNEE CHADHA</div>
            </div>
        </>
    )
}

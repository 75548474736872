import React from 'react'

export default function OrderForm({product}) {
    return (
        <div>
            <form className='order-form' action='https://formspree.io/f/mqkryebo' method='post'>
                <div className='inputs'>
                    <input className="input" name="product" id='product' placeholder="Product" type="text" autoComplete='off' value={product.name} />
                    <input className="input" name="price" placeholder="Price" type="text" autoComplete='off' value={product.price} />
                    <input className="input" name="name" placeholder="Name" type="text" autoComplete='off' />
                    <input className="input" name="phone" placeholder="Phone" type="number" autoComplete='off' />
                    <input className="input" name="address" placeholder="Address" type="text" autoComplete='off' />
                </div>
                <button class="button-sub" type='submit'>Order Now</button>
            </form>
        </div>
    )
    
}

import React from 'react'

export default function Team() {
    return (
        <div className='about-section'>
            <h2 className='heading'>ABOUT US</h2>
            <br />
            <h4>Welcome to Crafts 'n' Creations: Where Creativity Knows No Bounds!</h4>
            <br />
            <p>Crafts 'n' Creations is not just a business; it's a passion project brought to life by a team of young and dynamic individuals who believe in the power of imagination and creativity. Founded by seven visionary kids - <b>Aishnee Chadha, Aashiya Prasad, Sarvnee Chadha, Jia Kaur, Siddhi Kohli, Taneesha Bhambhani, and Aarav Prasad</b> - Crafts 'n' Creations is dedicated to bringing joy and innovation to the world of art and design. As the CEO of Crafts 'n' Creations, Aishnee Chadha leads with unwavering determination and a keen eye for business strategies. Aashiya Prasad, our Co-Founder, brings her entrepreneurial spirit and creative flair to drive our success forward. Jia Kaur and Siddhi Kohli, our Chief Development Officer and Co-Chief Development Officer, play pivotal roles in nurturing talent and fostering growth within our team. Their dedication to employee development ensures that every member of the Crafts 'n' Creations family is empowered to reach their full potential and contribute to our collective success. Sarvnee Chadha, the Discoverer of Laws and New Products, stands at the forefront of product discovery and innovation. With her keen eye for trends and passion for exploring new horizons, Sarvnee continuously enriches our offerings with exciting new products and ideas. In the realm of marketing, Taneesha Bhambhani shines bright, utilizing her skills to spread the word about our incredible products and connect with our valued customers. Aarav Prasad, our talented designer, brings visions to life with his unparalleled creativity and attention to detail. His designs capture the essence of Crafts 'n' Creations, infusing each piece with beauty and craftsmanship.
            </p>
            <br />
            <p> Crafts 'n' Creations is your one-stop destination for all things artistic and innovative. From exquisite art pieces that adorn your walls to trendy loom bands that add a pop of color to your ensemble, we offer a diverse range of products to cater to every taste and style. Explore our collection of earrings and necklaces, meticulously crafted to elevate your look and express your individuality. Dive into the world of bead bands, where endless possibilities await to adorn your wrists and necklines with elegance and charm. But that's not all at Crafts 'n' Creations, we go beyond traditional art forms to bring you cutting-edge accessories like iPhone cases and device chains, designed to seamlessly blend style with functionality. Personalize your space with our customizable nameplates, or indulge in the excitement of our goodie bags and blind bags, filled with surprises waiting to be discovered. And the adventure doesn't end there - stay tuned for our upcoming products, where innovation knows no bounds and creativity knows no limits. At Crafts 'n' Creations, we are committed to pushing the boundaries of imagination and bringing joy to every customer, one creation at a time. Join us on this extraordinary journey of creativity and discovery. Shop Crafts 'n' Creations today and experience the magic of handmade artistry like never before!
            </p>
            {/* <div className='team'>
                <div className='member'>
                    <img src='images/aishnee.jpeg' alt='team-member' />
                    <div className='member-details'><p>Aishnee Chadha</p><p>Chief Executive Officer</p></div>
                </div>
                <div className='member'>
                    <img src='images/aashiya.jpeg' alt='team-member' />
                    <div className='member-details'><p>Aashiya Prasad</p><p>Co-Founder</p></div>
                </div>
                <div className='member'>
                    <img src='images/siddhi.jpeg' alt='team-member' />
                    <div className='member-details'><p>Siddhi Kohli</p><p>Chief Development Party</p></div>
                </div>
                <div className='member'>
                    <img src='images/jia.jpeg' alt='team-member' />
                    <div className='member-details'><p>Jia Kaur</p><p>Chief Development Party</p></div>
                </div>
                <div className='member'>
                    <img src='images/aarav.jpeg' alt='team-member' />
                    <div className='member-details'><p>Aarav Prasad</p><p>Designer</p></div>
                </div>
                <div className='member'>
                    <img src='images/taneesha.jpeg' alt='team-member' />
                    <div className='member-details'><p>Taneesha Bambani</p><p>Marketing</p></div>
                </div>
                <div className='member'>
                    <img src='images/sarvnee.jpeg' alt='team-member' />
                    <div className='member-details'><p>Sarvnee Chadha</p><p>Discoverer</p></div>
                </div>
            </div> */}
        </div>
    )
}

import React from 'react';

const CurrencyDropdown = ({ currencies, selectedCurrency, onSelectCurrency }) => {
  return (
    <select className="currency-dropdown" value={selectedCurrency} onChange={(e) => onSelectCurrency(e.target.value)}>
      {currencies.map((currency) => (
        <option key={currency} value={currency}>
          {currency}
        </option>
      ))}
    </select>
  );
};

export default CurrencyDropdown;

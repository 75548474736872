import React from 'react'
import { FaInstagram, FaWhatsapp, FaYoutube, FaPinterest } from "react-icons/fa";

export default function Icons() {
  return (
    <div className='icons'>
        <a href='/'><FaInstagram /></a>
        <a href='/'><FaWhatsapp /></a>
        <a href='/'><FaYoutube /></a>
        <a href='/'><FaPinterest /></a>
    </div>
  )
}

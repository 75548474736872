const productsData = [
    {
        id: 1,
        name: 'Name Plate',
        price: '150',
        imageUrl: 'images/NAME PLATE.jpg',
    }, 
    {
        id: 2,
        name: 'Blind Bag',
        price: '150',
        imageUrl: 'images/BLIND BAG.jpg',
    },
    {
        id: 3,
        name: 'Blue Art Piece',
        price: '40',
        imageUrl: 'images/ART PIECE BLUE.jpg',
    },
    {
        id: 4,
        name: 'Bracelet Loom',
        price: '80',
        imageUrl: 'images/BRACELET LOOM.jpeg',
    },
    {
        id: 5,
        name: 'Bracelet Beads',
        price: '80',
        imageUrl: 'images/BRACELET BEADS.jpg',
    },
    {
        id: 6,
        name: 'Goodies Bag',
        price: '100',
        imageUrl: 'images/GOODIES BAG.jpg',
    },
    {
        id: 7,
        name: 'Art Piece Pink',
        price: '40',
        imageUrl: 'images/ART PIECE PINK.jpg',
    },
    {
        id: 8,
        name: 'Art Piece Mix',
        price: '40',
        imageUrl: 'images/ART PIECE MIX.jpg',
    },
    {
        id: 9,
        name: 'Loom Necklace',
        price: '100',
        imageUrl: 'images/LOOM NECKLACE.jpg',
    },
    {
        id: 10,
        name: 'Earring Loom',
        price: '80',
        imageUrl: 'images/EARRING LOOM.jpeg',
    },
    
    
];

export default productsData;
